import React, { useEffect } from 'react';
import logo from '../../assets/img/cr-logo-white.svg';
import LoginFormWrapper from './LoginFormWrapper';
import LoginProvider from './LoginProvider';
import './style.scss';

const LoginScreen: React.FC = () => {
  useEffect(() => {
    const redirectToEditorDomain = () => {
      const domain = window.location.hostname;
      if (domain.startsWith('app.')) {
        const newDomain = domain.replace('app.', 'editor.');
        window.location.replace(`https://${newDomain}`);
      }
    };

    redirectToEditorDomain();
  }, []);

  return (
    <LoginProvider>
      <div className="login-screen">
        <div>
          <a href="/"><img className="logo" src={logo} alt="Logo" /></a>
        </div>
        <LoginFormWrapper />
      </div>
    </LoginProvider>
  );
};

export default LoginScreen;
